import type { BuildLinkInterfaceParams } from '~/utils/buildLinkInterface';
import { buildLinkInterface } from '~/utils/buildLinkInterface';

export function useBuildLinkInterface(
  element?: BuildLinkInterfaceParams,
  withAbsolutePath = false
) {
  const locale = useLocale();
  const logger = useLogger();

  return buildLinkInterface(element, locale.value, withAbsolutePath, logger);
}
